/* Add here all your css styles (customizations) */

// Variables
@import "custom.variables";

// Mixins
@import "custom.mixins";

ul.squarelist, ul.squarelist li {
	list-style-type: square;
}
footer {
	font-size: 0.9rem;
}